<template>
  <div class="sub-cont">
    <div class="request-tapmenu-wrap">
      <ul style="cursor: pointer">
        <li v-if="isEmployer">
          <router-link
            :class="{ on: routeName === 'JobDocumentList' }"
            to="/request"
          >
            공고별
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'requestList' }"
            to="/requestList"
          >
            근무요청
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'attendanceList' }"
            to="/attendance"
          >
            출 &#183; 퇴근
          </router-link>
        </li>
        <li>
          <router-link
            :class="{ on: routeName === 'mypageCalcHistory' }"
            to="/mypage/calc-history"
          >
            정산
          </router-link>
        </li>
      </ul>
    </div>
    <div class="myp-buy-wrap">
      <div class="search-item-wrap">
        <div class="label">근무일자</div>
        <div class="date-item-wrap">
          <input
            type="text"
            class="inp datepicker"
            inputmode="none"
            id="workingStartDt"
            :value="workingStartDt"
          />
          <span class="e01">-</span>
          <input
            type="text"
            class="inp datepicker"
            inputmode="none"
            id="workingEndDt"
            :value="workingEndDt"
          />
        </div>
        <div class="opt-item-wrap">
          <select v-model="workingStatus" class="select">
            <option
              v-for="statusItem in WORKING_STATUS"
              :key="statusItem.value"
              :value="statusItem.value"
            >
              {{ statusItem.name }}
            </option>
          </select>
          <input
            v-model="schKeyword"
            type="text"
            class="inp"
            placeholder="근무지, 직종 검색"
          />
        </div>
        <button type="button" @click="onGoPage(1)">검색</button>
      </div>
      <table class="tbl01">
        <colgroup>
          <col width="" />
          <col width="180" />
          <col width="" />
        </colgroup>
        <tr>
          <th>근무일자</th>
          <th>{{ `${isEmployee ? '업체명' : '이름'}` }}</th>
          <th>급여</th>
          <th>상태</th>
        </tr>

        <tr
          v-for="attItem in attendanceList"
          :key="attItem.workingId"
          @click="$router.push(`/attendance/${attItem.workingId}`)"
          style="cursor: pointer"
        >
          <td>{{ attItem.workingDate }}</td>
          <td>
            {{ isEmployee ? attItem.employerName : attItem.employeeName }}
            <span v-if="!isEmployee">{{ attItem.positionName }}</span>
          </td>
          <td>
            {{ `${insertCommaToNumber(attItem.payAmt)}원` }}
          </td>
          <td>
            <div
              class="status"
              :class="{
                wait:
                  attItem.workingStatus === WORKING_STATUS[1].value || //출근전
                  attItem.workingStatus === WORKING_STATUS[6].value || //노쇼
                  attItem.workingStatus === WORKING_STATUS[7].value, //지급보류
                hold:
                  attItem.workingStatus === WORKING_STATUS[2].value || //출근도장
                  attItem.workingStatus === WORKING_STATUS[4].value || //퇴근도장
                  attItem.workingStatus === WORKING_STATUS[9].value, //취소
                succ:
                  attItem.workingStatus === WORKING_STATUS[8].value || //지급확정
                  attItem.workingStatus === WORKING_STATUS[3].value || //출근확인
                  attItem.workingStatus === WORKING_STATUS[5].value, //퇴근확인,
              }"
            >
              {{ WORKING_STATUS_NAME[attItem.workingStatus] }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4" v-if="attendanceList.length == 0">
            근태내역이 존재하지 않습니다.
          </td>
        </tr>
        <!-- <tr>
          <td>2021.07.26</td>
          <td @click="$router.push('/attendance/1')">
            홍길동 <span v-if="!isEmployee">룸메이드 청소</span>
          </td>
          <td>
            <div class="status wait">대기중</div>
          </td>
        </tr>
        <tr>
          <td>2021.07.26</td>
          <td @click="$router.push('/attendance/1')">
            홍길동 <span v-if="!isEmployee">룸메이드 청소</span>
          </td>
          <td>
            <div class="status hold">대기중</div>
          </td>
        </tr>
        <tr>
          <td>2021.07.26</td>
          <td @click="$router.push('/attendance/1')">
            홍길동 <span v-if="!isEmployee">룸메이드 청소</span>
          </td>
          <td>
            <div class="status succ">완료</div>
          </td>
        </tr> -->
      </table>
      <!-- 페이징 추가 -->
      <Pagination
        :totalElements="totalElements"
        :currentPageIndex="currentPageIndex"
        :listRowCount="listRowCount"
        :pageLinkCount="pageLinkCount"
        @goPage="onGoPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import {
  getAttendanceListForEmployer,
  getAttendanceListForEmployee,
} from 'Api/modules'
import datePickerMixin from 'Mixins/datePicker'
import { WORKING_STATUS, WORKING_STATUS_NAME } from 'Configs/workingStatus'
import moment from 'moment'
import Pagination from 'Common/Pagination'
export default {
  components: {
    Pagination,
  },
  mixins: [datePickerMixin],
  data() {
    return {
      WORKING_STATUS: Object.freeze(WORKING_STATUS),
      WORKING_STATUS_NAME: Object.freeze(WORKING_STATUS_NAME),
      attendanceList: [],

      workingStartDt: '',
      workingEndDt: '',
      workingStatus: WORKING_STATUS[0].value,
      schKeyword: '',
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 20, //근태목록은 서버에서 사이즈가 20으로 맞춰져있어 수정
      pageLinkCount: 5,
      currentPageIndex: 1,
      isVisibleLeftTab: false,
    }
  },
  watch: {
    workingStartDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.workingEndDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.workingEndDt) - 1 < 0) {
            this.setDatePickerDate('workingEndDt', newVal, this.setEndDate)
            this.workingEndDt = newVal
          }
        }
      }
    },
    workingEndDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.workingStartDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.workingStartDt, newVal) - 1 < 0) {
            this.setDatePickerDate('workingStartDt', newVal, this.setStartDate)
            this.workingStartDt = newVal
          }
        }
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    switchLeftTab() {
      this.isVisibleLeftTab = true
    },
    switchRightTab() {
      this.isVisibleLeftTab = false
    },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    setStartDate(selectedDate) {
      this.workingStartDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.workingEndDt = selectedDate
    },
    async search(setQuery = true) {
      if (!this.workingStartDt || !this.workingEndDt) {
        return false
      }
      //구직회원
      if (this.isEmployee) {
        const res = await getAttendanceListForEmployee({
          employeeId: this.account.userId,
          workingStartDt: this.workingStartDt,
          workingEndDt: this.workingEndDt,
          workingStatus: this.workingStatus,
          schKeyword: this.schKeyword,
          pageNumber: this.pageNumber,
        })
        this.attendanceList = res.content
        this.totalElements = res.totalElements
        this.currentPageIndex = res.pageable.pageNumber + 1
        this.pageNumber = res.pageable.pageNumber
      }
      //구인회원
      else {
        const res = await getAttendanceListForEmployer({
          employerId: this.account.userId,
          workingStartDt: this.workingStartDt,
          workingEndDt: this.workingEndDt,
          workingStatus: this.workingStatus,
          schKeyword: this.schKeyword,
          pageNumber: this.pageNumber,
        })
        this.attendanceList = res.content
        this.totalElements = res.totalElements
        this.currentPageIndex = res.pageable.pageNumber + 1
        this.pageNumber = res.pageable.pageNumber
      }

      if (setQuery) {
        const query = {
          workingStartDt: this.workingStartDt,
          workingEndDt: this.workingEndDt,
          workingStatus: this.workingStatus,
          schKeyword: this.schKeyword,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'attendanceList',
          query,
        })
      }
    },

    setFromQueryParam() {
      const {
        workingStartDt,
        workingEndDt,
        workingStatus,
        schKeyword,
        pageNumber,
      } = this.$route.query

      this.workingStartDt = workingStartDt
      this.setDatePickerDate(
        'workingStartDt',
        workingStartDt,
        this.setStartDate,
      )
      this.workingEndDt = workingEndDt
      this.setDatePickerDate('workingEndDt', workingEndDt, this.setEndDate)
      this.workingStatus = workingStatus
      this.schKeyword = schKeyword
      this.pageNumber = pageNumber

      if (!this.workingStartDt && !this.workingEndDt) {
        const today = moment().format('YYYY-MM-DD')
        const momentday = moment().add(1, 'months').format('YYYY-MM-DD')
        this.setDatePickerDate('workingStartDt', today, this.setStartDate)
        this.workingStartDt = today
        this.setDatePickerDate('workingEndDt', momentday, this.setEndDate)
        this.workingEndDt = momentday
      }
    },
  },
  async created() {
    this.initDatePicker('workingStartDt', this.setStartDate)
    this.initDatePicker('workingEndDt', this.setEndDate)

    this.setFromQueryParam()

    await this.search(false)
  },
}
</script>

<style lang="scss" scoped>
.tbl01 {
  tr > td:nth-child(2) {
    cursor: pointer;
  }
}
</style>
